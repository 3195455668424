<script>
import storage from "@/services/web-storage";

export default {
  name: 'Start',
  async created() {
    await storage.clear();
  }
}
</script>

<template>
  <div>
    <div
      class="text-center fill-height start-container"
      style="height: calc(100vh - 58px)"
    />
    <div class="btn-box d-flex justify-center align-center flex-column mx-auto pa-10" style="position: absolute; border-radius: 35px; background-color: white">
      <p>Онлайн-викторина</p>
      <v-btn
        v-if="false"
        to="/admin/auth"
        color="primary"
        depressed
        block
        class="mb-5"
      >
        {{ $t("pages.start.btn-admin") }}
      </v-btn>
      <v-btn
        to="/auth"
        color="primary"
        depressed
        block
        large
      >
        Начать
      </v-btn>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.start-container {
  background-image: url('../assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  filter: grayscale(0) blur(0px);
}
.btn-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
